






























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import CustomTimeClock from '@/components/CustomTimeClock.vue';

@Component({ 
    components: {
        CustomTimeClock
    }
})
export default class Schedules extends Vue{
    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;

    @Prop() time_points;
    @Prop() schedule_index;

    get on_schedule_options(){
        return [{text: this.$t('Custom'), value: 'time'}, {text: this.$t('Dusk'), value: 'dusk'}];
    }

    get off_schedule_options(){
        return [{text: this.$t('Custom'), value: 'time'}, {text: this.$t('Dawn'), value: 'dawn'}];
    }

    get offset_options(){
        return [{text: this.$t('Before'), value: '-'}, {text: this.$t('After'), value: '+' }];
    }
}

