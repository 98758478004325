




















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Schedules from './Schedules.vue';

@Component({ 
    components: {
        Schedules
    }
})
export default class ScheduleStep extends Vue{
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('dusk_dawn_data') dusk_dawn_data;
    @Global.State('timezone') projectTimezone;

    @Prop() schedule;
    @Prop() daily_schedule_display;
    @Prop() days_in_week;
    @Prop() current_step;
    @Prop() update_schedules_data;
    @Prop() is_days_schedule_error;

    current_schedule = null;
    current_daily_schedule_display = null;
    is_daily_schedule_active = true;

    mounted() {
        this.current_schedule = cloneDeep(this.schedule);
        this.current_daily_schedule_display = cloneDeep(this.daily_schedule_display);
        if (this.current_daily_schedule_display.length) {
            this.is_daily_schedule_active = this.current_daily_schedule_display[0].active;
        }
    }

    addNewSchedule(type){
        if (type === 'default'){
            if (this.current_schedule.time_points) {
                this.current_schedule.time_points.push({on: {type: 'dusk', value: '-00:15'}, off: {type: 'dawn', value: '+00:15'}});
            }else {
                Vue.set(this.current_schedule, 'time_points', [{on: {type: 'dusk', value: '-00:15'}, off: {type: 'dawn', value: '+00:15'}}]);
            }
            this.current_schedule.active = true;
        }else {
            this.current_daily_schedule_display.push({
                active: true,
                days: [],
                time_points: [{on: {type: 'dusk', value: '-00:15'}, off: {type: 'dawn', value: '+00:15'}}]
            });
        }
    }

    updateBothSchedulesTypeStatus(status){
        this.updateAllDailySchedulesStatus(status);
        this.is_daily_schedule_active = status;
    }

    updateAllDailySchedulesStatus(status){
        this.current_daily_schedule_display.forEach((daily_schedule) => daily_schedule.active = status);
        if (status && !this.current_schedule.active) {
            this.current_schedule.active = true;
        } 
    }

    removeSchedule(time_point_index, schedule_index){
       this.current_schedule.time_points = this.current_schedule.time_points.filter((schedule, index) => index !== time_point_index);
       if (this.current_daily_schedule_display.length) {
        this.current_daily_schedule_display = [];
       }
    }

    removeScheduleFromDailySet(time_point_index, special_set_index){
        const new_time_points = this.current_daily_schedule_display[special_set_index].time_points.filter((schedule, index) => index !== time_point_index);
        if (new_time_points.length){
            this.current_daily_schedule_display[special_set_index].time_points = new_time_points;
        }else {
            this.current_daily_schedule_display = this.current_daily_schedule_display.filter((schedule, index) => index !== special_set_index);
        }
    }

    updateScheduleDays(special_set_index, selected_day){
        const current_days = this.current_daily_schedule_display[special_set_index].days;

        this.current_daily_schedule_display[special_set_index].days = current_days.includes(selected_day)
            ? current_days.filter((day) => day !== selected_day)
            : [...current_days, selected_day];
    }

    updateTimePoint(time_point_index, field1, field2, value, schedule_index){
        this.current_schedule.time_points[time_point_index][field1][field2] = value;
        if (field1 === 'on' && field2 === 'type'){
            if (value === 'dusk') {
                this.current_schedule.time_points[time_point_index][field1].value = '-00:15';
            }else {
                this.current_schedule.time_points[time_point_index][field1].value = '00:00';
            }
        }else if (field1 === 'off' && field2 === 'type'){
            if (value === 'dawn') {
                this.current_schedule.time_points[time_point_index][field1].value = '+00:15';
            }else {
                this.current_schedule.time_points[time_point_index][field1].value = '00:00';
            }
        }
    }

    updateDailyTimePoint(time_point_index, field1, field2, value, daily_schedule_index){
        this.current_daily_schedule_display[daily_schedule_index].time_points[time_point_index][field1][field2] = value;

        if (field1 === 'on' && field2 === 'type'){
            if (value === 'dusk') {
                this.current_daily_schedule_display[daily_schedule_index].time_points[time_point_index][field1].value = '-00:15';
            }else {
                this.current_daily_schedule_display[daily_schedule_index].time_points[time_point_index][field1].value = '00:00';
            }
        }else if (field1 === 'off' && field2 === 'type'){
            if (value === 'dawn') {
                this.current_daily_schedule_display[daily_schedule_index].time_points[time_point_index][field1].value = '+00:15';
            }else {
                this.current_daily_schedule_display[daily_schedule_index].time_points[time_point_index][field1].value = '00:00';
            }
        }
    }

    @Watch('current_schedule', {deep: true, immediate: true})
    @Watch('current_daily_schedule_display', {deep: true, immediate: true})
    updateApplyButton(){
        this.$emit('setIsDaysScheduleError', this.current_daily_schedule_display.some((daily_schedule) => !daily_schedule.days.length));
        this.$nextTick(() => {
            const apply_disabled = (isEqual(this.current_schedule, this.schedule) && 
                isEqual(this.current_daily_schedule_display, this.daily_schedule_display)) ||
                this.is_days_schedule_error;

            this.$emit('setApplyBtnIsDisabled' , apply_disabled);
        });
    }

    @Watch('current_step')
    handleStepChanged(){
        this.$nextTick(() => {
            if (this.current_step !== 2 && this.update_schedules_data){
                this.$emit('updateSchedules', this.current_schedule, this.current_daily_schedule_display);
            }else if (!this.update_schedules_data){
                this.current_schedule = cloneDeep(this.schedule);
                this.current_daily_schedule_display = cloneDeep(this.daily_schedule_display);
            }
        });
    }
}

